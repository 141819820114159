import { createApp } from 'vue'
import App from './App.vue'

import { IonicVue } from '@ionic/vue'
import '@ionic/vue/css/ionic.bundle.css'
import './registerServiceWorker'
import './styles.css'

import { maskito } from '@maskito/vue'

createApp(App).directive('maskito', maskito).use(IonicVue).mount('#app')
